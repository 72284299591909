<template>
  <div id="app">

    <router-view/>
    <footbar />
  </div>
</template>
<script>
import Footbar from "@/components/footbar";
export default {
  components: {Footbar}
}
</script>