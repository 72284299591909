<template>
  <div style="background-image: url('/mattress.jpg')">
    <div class="uk-padding-large" style="background-color: rgba(255, 255, 255, 0.7)">
      <div class="uk-container">
        <h2 class="uk-text-center">How the process works</h2>
        <div class="uk-child-width-1-3" uk-grid>
          <div>

            <h4 class="uk-text-bold"><span uk-icon="file-edit" style="color: deepskyblue"></span> Step 1 </h4>
            <p>
              Fill out the application form
            </p>
            <a href="https://docs.google.com/forms/d/1hePKL7llG81qFhyWZxjYdpT8VB_88Esk629pMUosXy4/viewform?edit_requested=true" target="_blank" class="uk-button uk-button-primary uk-text-capitalize uk-button-small">
              Go to form <span uk-icon="arrow-right"></span>
            </a>
          </div>
          <div>
            <h4 class="uk-text-bold"><span uk-icon="gitter" style="color: deepskyblue"></span> Step 2 </h4>
            We reach out and begin organizing the trip along with you so we can start preparing all the logistics around your trip

          </div>
          <div>
            <h4 class="uk-text-bold"><span uk-icon="album" style="color: deepskyblue"></span> Step 3</h4>
            Gather your Diet profile so we can always have the food you want to eat
          </div>
          <div>
            <h4 class="uk-text-bold"><span uk-icon="credit-card" style="color: deepskyblue"></span> Step 4</h4>
            Agree on payment details for the trip
          </div>
          <div>
            <h4 class="uk-text-bold"><span uk-icon="heart" style="color: deepskyblue"></span> Step 5</h4>
            Relax and enjoy your vacation while we work for you
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OnboardingProcess"
}
</script>

<style scoped>

</style>
