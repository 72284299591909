import Vue from 'vue'
import VueRouter from 'vue-router'
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import 'uikit/dist/css/uikit.min.css'
import Home from '../views/Home.vue'
import About from "@/views/About";
import ContactUs from "@/views/ContactUs";
import PhotoGallery from "@/views/PhotoGallery";
import Services from "@/views/Services";
import ReviewBoard from "@/views/ReviewBoard";

UIkit.use(Icons);
Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/about', name: 'About Us', component: About },
  { path: '/contact', name: 'Contact Us', component: ContactUs },
  { path: '/photo_gallery', name: 'Photo Gallery', component: PhotoGallery },
  { path: '/services', name: 'Services', component: Services },
  { path: '/review_board', name: 'Review Board', component: ReviewBoard },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
