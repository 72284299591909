<template>
  <div class="uk-padding uk-background-muted">
    <div class="uk-container">
      <h2 class="uk-heading-line uk-text-center"><span>Vacation Board Out</span></h2>
      <div class="uk-panel">
        <img class="uk-align-left uk-margin-remove-adjacent uk-border-rounded" src="../assets/photo8.jpeg" style="max-width: 400px; max-height: 400px; width: auto; height: auto" alt="Example image">
        <p>
          Vacation Board Out is a newly, innovative, registered and privately owned business;
          that comprises staff of: caregivers, medical team, chef, housekeeping and transportation (which may include
          excursions around the beautiful island of Jamaica). We are home based in fourteen parishes which offer the
          many services we provide.
        </p>
        <p>
          Vacation Board out is a ‘one-stop-shop’ which encompasses all other amenities Jamaica has to offer.
          We take full responsibility and accountability of our clients from the point of landing in Jamaica;
          to departure, which includes, picking up and returning to the airport.
        </p>
        <p>
          We offer an all inclusive package that is very competitive and unstoppable. There are no exceptions in
          catering for the elders, regardless of their ability to be independent or not. The health and active aging
          exposure is valued here in admiration. We administer the very simplest of care whether to bathe or clean stools.
        </p>
        <p>
          Our aim is to serve and give a ‘one-in-a-lifetime’ generational experience with fidelity.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutSnip"
}
</script>

<style scoped>

</style>
