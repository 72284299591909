<template>
  <div class="uk-padding">
    <div class="uk-container uk-container-small">
      <div class="uk-child-width-1-3@m" uk-grid>
        <div>
          <div class="uk-card uk-card-default">
            <div class="uk-card-media-top">
              <img src="/review1.jpg" width="1800" height="1200" alt="">
            </div>
            <div class="uk-card-body uk-padding-small"  >
              <h3 class="uk-card-title">Jennifer Gillingham </h3>
              <p class="uk-text-small">Thanks to VBO for ensuring i experienced the best version of Jamaica. The fun is in the hidden gems on the sland.
              They uncovered all the gems for my experience. Only fun and good vibes</p>
            </div>
          </div>
        </div>
        <div>
          <div class="uk-card uk-card-default">
            <div class="uk-card-media-top">
              <img src="/review2.jpg" width="1800" height="1200" alt="">
            </div>
            <div class="uk-card-body uk-padding-small"  >
              <h3 class="uk-card-title">Deadre Li</h3>
              <p class="uk-text-small">
                This is a must recommend from me.
                They ensured I enjoyed my first trip to Jamaica ... wish I could had booked more time. Best trip ever.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="uk-card uk-card-default">
            <div class="uk-card-media-top">
              <img src="../assets/photo3.jpeg" width="1800" height="1200" alt="">
            </div>
            <div class="uk-card-body uk-padding-small"  >
              <h3 class="uk-card-title">Jamar Curry</h3>
              <p class="uk-text-small">I was hesitant to travel to Jamaica as an elder who cant do much on my own. VBO made this easy by tailoring everything
               to me from my recommended diet to assisting with my elderly needs. I thought vacationing was experience was done for me but the
              allowed me to relive the fun of my youthfulness</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <br>
        <router-link to="/review_board" class="uk-button uk-button-primary uk-text-capitalize uk-align-right@m">
          View More
          <span uk-icon="chevron-right"></span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReviewSnip"
}
</script>

<style scoped>

</style>
