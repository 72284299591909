<template>
  <div>
    <navbar />
    <div class="uk-background-muted">
      <ECard />
    </div>
    <ContactForm />
    <FindUs />

  </div>
</template>

<script>
import Navbar from "@/components/navbar";
import ECard from "@/components/ECard";
import ContactForm from "@/components/ContactForm";
import FindUs from "@/components/FindUs";
export default {
  name: "ContactUs",
  components: {FindUs, ContactForm, ECard, Navbar}
}
</script>

<style scoped>

</style>
