<template>
  <div>
    <navbar />
    <div style="min-height: 70vh">
      <div class="uk-padding uk-background-muted" >
        <div class="uk-container">
          <h1 class="uk-text-center">Services</h1>
          <p>Once a person is in our care, we give all the services below to them as apart of our package.
            We love to serve you and we do our best to ensure that you will, in return, love our service too.</p>
          <div class="uk-child-width-1-4@m" uk-grid>
            <div class="uk-padding-small">
              <div class="uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-small">
                <h3 class="uk-card-title">Accommodations</h3>

              </div>
            </div>
            <div class="uk-padding-small">
              <div class="uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-small">
                <h3 class="uk-card-title">Medication Monitoring</h3>

              </div>
            </div>
            <div class="uk-padding-small">
              <div class="uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-small " style="background-color: gold">
                <h3 class="uk-card-title">Nutritional Care</h3>

              </div>
            </div>
            <div class="uk-padding-small">
              <div class="uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-small">
                <h3 class="uk-card-title">Assisted Living</h3>
                <!--              <p>Lorem ipsum <a href="#">dolor</a> sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
              </div>
            </div>

          </div>

        </div>
      </div>
      <div class="uk-padding">
        <div class="uk-container">
          <h4 class="uk-heading-bullet uk-text-bold">Medical Monitoring </h4>
          <div class="uk-panel">
            <img class="uk-align-left uk-margin-remove-adjacent" src="../assets/doctor.jpg" style="max-width: 300px; max-height: 300px; width: auto; height: auto"  alt="Example image">
            <p><br></p>
            <p>We have caregivers that are available to give care 24/7 for all your needs.</p>
            <p>We ensure that all our clients are properly taken care of. In the need that an emergency arises we have doctors that are on standby to ensure our clients in good hands.</p>
            <p>
              We offer chauffeur services to all our clients to and from all destinations that they wish to visit. This is done with their
              safety and enjoyment at the forefront of our thoughts always.
            </p>
            </div>

        </div>
      </div>
      <div class="uk-padding uk-background-muted" style="background-color: gold">
        <div class="uk-container">
          <h4 class="uk-heading-bullet uk-text-bold">Nutritional Care</h4>
          <div class="uk-panel">
            <img class="uk-align-right@m uk-margin-remove-adjacent" src="../assets/vegetables.png" style="max-width: 400px; max-height: 400px; width: auto; height: auto"  alt="Example image">
<!--            <p>

              We create diets for all our guest based on your specific needs or ailments to ensure that you feel at home

              Every meal is balanced with all the essential nutrients packed in each sitting to ensure your nutrition is at its peak levels while you are with us

              We ensure that all our guests taste the island by providing to them a wide array of fruits local to Jamaica such as otaiti apples, East Indian Mangoes, etc

            </p>-->
            <p>We create diets for all our guest based on your specific needs or ailments to ensure that you feel at home</p>
            <p>Every meal is balanced with all the essential nutrients packed in each sitting to ensure your nutrition is at its peak levels while you are with us</p>
            <p>
              We ensure that all our guests taste the island by providing them a wide array of fruits local to Jamaica such as otaheite apples, East Indian Mangoes, etc
            </p>
          </div>
          <div>


          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navbar from "@/components/navbar";
export default {
  name: "Services",
  components: {Navbar}
}
</script>

<style scoped>

</style>
