<template>
  <div class="home">
    <navbar-landing />
<!--    <div class="uk-padding">
      <div class="uk-container">
        <h2 class="uk-text-center">Are you an elder who wants to vacation with us?</h2>
        <p class="uk-text-center">Here is how the process works</p>
      </div>
    </div>-->
    <AboutSnip />
    <OnboardingProcess />
    <LocationsSnip />
    <QuoteSnip />
    <ReviewSnip />

<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import NavbarLanding from "@/components/navbar_landing";
import ReviewSnip from "@/components/ReviewSnip";
import QuoteSnip from "@/components/QuoteSnip";
import LocationsSnip from "@/components/LocationsSnip";
import AboutSnip from "@/components/AboutSnip";
import OnboardingProcess from "@/components/OnboardingProcess";

export default {
  name: 'Home',
  components: {
    OnboardingProcess,
    AboutSnip,
    LocationsSnip,
    QuoteSnip,
    ReviewSnip,
    NavbarLanding,
    HelloWorld
  }
}
</script>
