<template>
  <div class="uk-background-secondary uk-padding-large">
    <div class="uk-container uk-container-small">
      <div  uk-grid>
        <div class="uk-width-expand@m " >
          <h4 class="uk-light">“Age is an issue of mind over matter, if you don’t mind, it doesn’t matter”</h4>
          <p>- Mark Twain</p>
        </div>
        <div class="uk-width-auto@m">
<!--          <span class="uk-light" uk-icon="quote-right"></span>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuoteSnip"
}
</script>

<style scoped>

</style>
