<template>
  <div class="uk-padding" style="background-color: #263238">
    <div class="uk-container uk-container-small">
      <div class="uk-text-center">
        <div>
          <a href="#">
            <img src="../assets/site_logo.jpeg" alt="" style="max-width: 60px; max-height: 60px; width: auto; height: auto">
          </a>
        </div>
        <div>
          <p class="uk-light">78 3/4 Hagley Park Rd, Kingston 11, Jamaica, <br>
            Phone: 1 876-334-9016 <br>
            Email: vacationboardout1@gmail.com</p>
        </div>
        <div class="uk-text-center">
<!--          <a href="#" uk-icon="facebook" class="uk-icon-button" style="color: #3b5998; margin-right: 5px"></a>-->
          <a href="https://www.instagram.com/vacationboardout1/" target="_blank" uk-icon="instagram" class="uk-icon-button" style="color: #962fbf;  margin-right: 5px"></a>
<!--          <a href="#" uk-icon="youtube" class="uk-icon-button" style="color: #800"></a>-->
          <div class=""></div>
          <div class=""></div>
          <div class=""></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footbar"
}
</script>

<style scoped>

</style>
