<template>
  <div class="uk-padding">
    <div class="uk-container">
      <h3 class="uk-heading-bullet">Leave us a message</h3>
      <form>
        <fieldset class="uk-fieldset">


          <div class="uk-margin">
            <input class="uk-input" type="text" placeholder="Name" aria-label="Input">
          </div>
          <div class="uk-margin">
            <input class="uk-input" type="text" placeholder="Subject" aria-label="Input">
          </div>


          <div class="uk-margin">
            <textarea class="uk-textarea" rows="5" placeholder="Leave us a message" aria-label="Textarea"></textarea>
          </div>

          <div class="uk-margin">
            <button class="uk-button uk-button-primary">Send</button>
          </div>


        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactForm"
}
</script>

<style scoped>

</style>
