<template>
<div>
  <div style="background-color: #263238">

    <div uk-sticky="start: 200; animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent uk-light">

      <nav class="uk-navbar-container">
        <div class="uk-container ">
          <div uk-navbar="container: true" >
            <div class="uk-navbar-left">
              <ul class="uk-navbar-nav" >
                <li class="uk-active">
                  <a href="#">
                    <img src="../assets/site_logo.jpeg" alt="" style="max-width: 60px; max-height: 60px; width: auto; height: auto">
                  </a>
                </li>

              </ul>
            </div>
            <div class="uk-navbar-right uk-visible@m">
              <ul class="uk-navbar-nav" >
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/about">About Us</router-link></li>
                <li><router-link to="/services">Services</router-link></li>
                <li><router-link to="/review_board">Review Board</router-link></li>
                <li><router-link to="photo_gallery">Photo Gallery</router-link></li>
                <li><router-link to="/contact">Contact Us</router-link></li>
              </ul>
            </div>
            <div class="uk-navbar-right uk-hidden@m">
              <a href="#offcanvas-usage" uk-toggle><span uk-icon="menu"></span></a>
            </div>

            <div id="offcanvas-usage" uk-offcanvas>
              <div class="uk-offcanvas-bar">

                <ul class="uk-nav uk-nav-default">
                  <li><router-link to="/">Home</router-link></li>
                  <li><router-link to="/about">About Us</router-link></li>
                  <li><router-link to="/services">Services</router-link></li>
                  <li><router-link to="/review_board">Review Board</router-link></li>
                  <li><router-link to="photo_gallery">Photo Gallery</router-link></li>
                  <li><router-link to="/contact">Contact Us</router-link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
<!-- Start content here    -->
    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow="ratio: 8:3; animation: push">

      <ul class="uk-slideshow-items">
        <li><img src="../assets/couple.jpg" alt="" uk-cover></li>
        <li><img src="../assets/beach.jpg" alt="" uk-cover></li>
        <li><img src="../assets/bgq.jpg" alt="" uk-cover></li>
<!--        <li><img src="../assets/bg2.jpg" alt="" uk-cover></li>-->
<!--        <li>
          <img src="../assets/bg1.jpg" alt="" uk-cover>
        </li>-->
      </ul>

      <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
      <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>

    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "navbarLanding"
}
</script>

<style scoped>
  .uk-navbar-nav a{
    min-height: 70px;
  }
</style>
