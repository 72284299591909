<template>
  <div class="uk-container uk-container-xsmall uk-padding">
    <h1 class="uk-text-center">Contact Us </h1>
    <div>
      <div  uk-grid>
        <div class="uk-width-1-3@m">
          <img class="uk-border-rounded" src="../assets/site_logo.jpeg" alt="" style="max-width: 200px; max-height: 200px; width: auto; height: auto">
        </div>
        <div class="uk-width-2-3@m">
          <h3 class="uk-card-title uk-text-bold uk-text-primary">Vacation Board Out</h3>
          <div>
            <div uk-grid>
              <div class="uk-width-auto"><span uk-icon="location"></span> Address:</div>
              <div class="uk-width-expand">78 3/4 Hagley Park Rd, Kingston 11, Jamaica</div>
            </div>
          </div>
          <div>
            <div uk-grid>
              <div class="uk-width-auto"><span uk-icon="receiver"></span> Phone:</div>
              <div class="uk-width-expand"> 1  876 334-9016</div>
            </div>
          </div>

          <div>
            <div class="uk-margin-remove-top" uk-grid>
              <div class="uk-width-auto"><span uk-icon="mail"></span> Email:</div>
              <div class="uk-width-expand"> vacationboardout1@gmail.com</div>
            </div>
          </div>
          <div>
            <div class="uk-flex">
<!--              <div class=""><a href="#" uk-icon="facebook" class="uk-icon-button" style="color: #3b5998"></a></div>-->
              <div class=""><a href="https://www.instagram.com/vacationboardout1/" target="_blank" uk-icon="instagram" class="uk-icon-button" style="color: #962fbf"></a></div>
<!--              <div class=""><a href="#" uk-icon="youtube" class="uk-icon-button" style="color: #800"></a></div>-->

            </div>
          </div>



        </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "ECard"
}
</script>

<style scoped>

</style>
