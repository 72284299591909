<template>
  <div class="uk-padding uk-background-muted">
    <div class="uk-container">
      <h1 class="uk-text-center">Locations</h1>
      <p class="uk-text-center uk-margin-remove-top">Here are some of the locations our guests visit with our assisted care </p>
      <div class="uk-child-width-1-4@m" uk-grid>
        <div>
          <div class="uk-card uk-card-default uk-border-rounded">
            <div class="uk-card-media-top">
              <img class=" uk-border-rounded" src="../assets/dunn_river.jpg" width="1800" height="1200" alt="">
            </div>
            <div class="uk-card-body uk-padding-small"  >
              <h4 class="uk-card-title">Dunn's River Falls</h4>

            </div>
          </div>
        </div>
        <div>
          <div class="uk-card uk-card-default uk-border-rounded">
            <div class="uk-card-media-top">
              <img class=" uk-border-rounded" src="../assets/hope_zoo.jpg" width="1800" height="1200" alt="">
            </div>
            <div class="uk-card-body uk-padding-small"  >
              <h4 class="uk-card-title">Hope Zoo</h4>

            </div>
          </div>
        </div>
        <div>
          <div class="uk-card uk-card-default uk-border-rounded">
            <div class="uk-card-media-top">
              <img class=" uk-border-rounded" src="../assets/emancipation_park.jpg" width="1800" height="1200" alt="">
            </div>
            <div class="uk-card-body uk-padding-small"  >
              <h4 class="uk-card-title">Emancipation Park</h4>
            </div>
          </div>
        </div>
        <div>
          <div class="uk-card uk-card-default uk-border-rounded">
            <div class="uk-card-media-top">
              <img class=" uk-border-rounded" src="../assets/Lagunalum.jpg" width="1800" height="1200" alt="">
            </div>
            <div class="uk-card-body uk-padding-small"  >
              <h4 class="uk-card-title">Luminous Lake </h4>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LocationsSnip"
}
</script>

<style scoped>

</style>
