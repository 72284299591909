<template>
  <div class="about">
    <navbar />
    <div class="uk-padding uk-background-muted">
      <div class="uk-container">
        <h1 class="uk-text-center">About Us</h1>
        <br><br>
        <div class="uk-panel">
          <img class="uk-align-left uk-margin-remove-adjacent uk-border-rounded" src="../assets/photo8.jpeg" style="max-width: 400px; max-height: 400px; width: auto; height: auto" alt="Example image">
          <p>
            Vacation Board Out is a newly, innovative, registered and privately owned business;
            that comprises staff of: caregivers, medical team, chef, housekeeping and transportation (which may include
            excursions around the beautiful island of Jamaica). We are home based in fourteen parishes which offer the
            many services we provide.
          </p>
          <p>
            Vacation Board out is a ‘one-stop-shop’ which encompasses all other amenities Jamaica has to offer.
            We take full responsibility and accountability of our clients from the point of landing in Jamaica;
            to departure, which includes, picking up and returning to the airport.
          </p>
          <p>
            We offer an all inclusive package that is very competitive and unstoppable. There are no exceptions in
            catering for the elders, regardless of their ability to be independent or not. The health and active aging
            exposure is valued here in admiration. We administer the very simplest of care whether to bathe or clean stools.
          </p>
          <p>
            Our aim is to serve and give a ‘one-in-a-lifetime’ generational experience with fidelity.
          </p>
        </div>
      </div>
    </div>
    <div class="uk-padding">
      <div class="uk-container">
        <h1 class="">Meet the Leaders</h1>
        <br>
        <div class="uk-panel">
          <img class="uk-align-right@m uk-margin-remove-adjacent uk-border-rounded" src="../assets/photo8.jpeg" style="max-width: 400px; max-height: 400px; width: auto; height: auto" alt="Example image">
          <h2 class="uk-heading-bullet">Shanniele White (Founder)</h2>
          <p>
            When elders experience social, physical, mental and emotional challenges, it is not uncommon for their lives to unravel. My greatest passion is to engage, encourage and experience the joy and laughter of active aging among elders.
            </p>
          <p>
            I am now enhancing my goal of providing elders the opportunity to garner exposure while being offered the chance of a quality vacation. Being the ‘go to’ person in my family for support (despite the age), provided much experience for my passion.
            </p>
          <p>
            Nurturing and caring for people of all calibra allowed me to gain knowledge, love and compassion for the senior care industry, by applying best practices to create an engaging atmosphere for seniors.
          </p>
          <p>
            These experiences coupled with years of professional acknowledgement enhanced my mastery skills not limited to a positive attitude, trustworthy, empathy, observation, patient and consistent communication.
            </p>
          <p>
            In my free time, I listen to music, research, talk,  have a great time and spend time with my family.
          </p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Navbar from "@/components/navbar";
  export default{
    name: "About",
    components: {Navbar}
  }
</script>
