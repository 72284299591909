<template>
  <div class="uk-background-muted uk-padding">
    <div class="uk-container">
      <h2>Find Us</h2>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3794.3308087135065!2d-76.80285658464209!3d18.009844889293035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8edb3fb7ae094837%3A0xd5d567cff210f324!2s3%2C%204%20Hagley%20Park%20Rd%2C%20Kingston!5e0!3m2!1sen!2sjm!4v1675891925620!5m2!1sen!2sjm" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "FindUs"
}
</script>

<style scoped>

</style>
