<template>
  <div>
    <navbar />
    <div class="uk-padding">
      <div class="uk-container">
        <h1 class="uk-text-center">Photo Gallery</h1>
        <h5 class="uk-heading-bullet">Trip to Magaritaville </h5>
        <div class="uk-child-width-1-4@m" uk-grid="masonry: true" uk-lightbox="animation: slide">
          <div>
            <a class="uk-inline" href="/photo1.jpeg"  data-caption="Caption 1">
              <img src="../assets/photo1.jpeg" width="1800" height="1200"  class="uk-border-rounded" alt="">
            </a>
          </div>

          <div>
            <a class="uk-inline" href="/photo3.jpeg" data-caption="Caption 3">
              <img src="../assets/photo3.jpeg" width="1800" height="1200"  class="uk-border-rounded" alt="">
            </a>
          </div>
          <div>
            <a class="uk-inline" href="/photo6.jpeg" data-caption="Caption 3">
              <img src="../assets/photo6.jpeg" width="1800" height="1200"  class="uk-border-rounded" alt="">
            </a>
          </div>
          <div>
            <a class="uk-inline" href="/photo4.jpeg" data-caption="Caption 3">
              <img src="../assets/photo4.jpeg" width="1800" height="1200"  class="uk-border-rounded" alt="">
            </a>
          </div>
          <div>
            <a class="uk-inline" href="/photo5.jpeg" data-caption="Caption 3">
              <img src="../assets/photo5.jpeg" width="1800" height="1200"  class="uk-border-rounded" alt="">
            </a>
          </div>
          <div>
            <a class="uk-inline" href="/photo9.jpeg" data-caption="Caption 3">
              <img src="../assets/photo9.jpeg" width="1800" height="1200"  class="uk-border-rounded" alt="">
            </a>
          </div>

          <div>
            <a class="uk-inline" href="/photo7.jpeg" data-caption="Caption 3">
              <img src="../assets/photo7.jpeg" width="1800" height="1200"  class="uk-border-rounded" alt="">
            </a>
          </div>
          <div>
            <a class="uk-inline" href="/photo2.jpeg" data-caption="Caption 2">
              <img src="../assets/photo2.jpeg" width="1800" height="1200"  class="uk-border-rounded" alt="">
            </a>
          </div>

          <div>
            <a class="uk-inline" href="/photo8.jpeg" data-caption="Caption 3">
              <img src="../assets/photo8.jpeg" width="1800" height="1200"  class="uk-border-rounded" alt="">
            </a>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/navbar";
export default {
  name: "PhotoGallery",
  components: {Navbar}
}
</script>

<style scoped>

</style>